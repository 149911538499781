// 权限流程控制
import router from '@/router'
import store from '@/store'
import NProgress from 'nprogress' // 进度条组件
import 'nprogress/nprogress.css' // 进度条组件的样式

const whiteList = ['/login'] // 白名单

// // 全局前置守卫
router.beforeEach(async function(to, from, next) {
  // 开启进度条效果
  NProgress.start()
  // 是否有 token -> store
  if (store.getters.token) {
    // 在进行判断，是否是登录页
    if (to.path == '/login') {

      // 跳转到 主页
      next()
    }
    else {
       if (store.getters.role!=="") {
        console.log('进入');
        // 在此处，正式发起获取用户的操作
        // 因为，当前是一个异步操作，所以我们要强制等待当前操作完成，再进行 next 操作
        // 只能在组件中使用：this.$store
        await store.dispatch('permission/getRoutes')
      
      
        next() // 跳转
      }
      else {
        next() // 跳转
      }
     
    }
  }
  else {
    // 是否在白名单里面 path
    if (!whiteList.includes(to.path)) {
      next('/login')
      // 跳转到登录页
     
    }
    else {
      next()
      
    }
  }
  NProgress.done()
})

router.afterEach(() => {
  // 关闭进度条效果
  NProgress.done()
})
