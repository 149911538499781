import {
  getUploadToken
} from "../request/http";
import {
  Notification
} from "element-ui";
import * as qiniu from "qiniu-js";
import {
  MessageBox
} from 'element-ui'

// 多张图片上传
// 1.普通分享 2.pdf胶囊按钮分享 3.pdf海报分享 4.名片胶囊按钮分享 5.名片海报分享 6.资讯分享 7.资讯海报分享
export function textChoose(number) {
  let text 
    switch (number) {
      case 1:
        text = '普通分享'
        break;
        case 2:
          text = 'pdf胶囊按钮分享'
          break;
          case 3:
        text = 'pdf海报分享'
        break;
        case 4:
        text = '名片胶囊按钮分享'
        break;
        case 5:
        text = '名片海报分享'
        break;
        case 6:
        text = '资讯分享'
        break;
        case 7:
        text = '资讯海报分享'
        break;
      

      default:
        break;
    }
    return text
}



const formatNumber = n => {
  n = n.toString()
  return n[1] ? n : '0' + n
}
export function formatTimeTwo(number, format) {
  var formateArr = ['Y', 'M', 'D', 'h', 'm', 's'];
  var returnArr = [];
  var date = new Date(number * 1000);
  returnArr.push(date.getFullYear());
  returnArr.push(formatNumber(date.getMonth() + 1));
  returnArr.push(formatNumber(date.getDate()));
  returnArr.push(formatNumber(date.getHours()));
  returnArr.push(formatNumber(date.getMinutes()));
  returnArr.push(formatNumber(date.getSeconds()));
  for (var i in returnArr) {
      format = format.replace(formateArr[i], returnArr[i]);
  }
  return format;
}

export default {
  morePicture: function (evfile) {
    return new Promise((resolve, reject) => {
      var targetFiles = evfile.target.files;
      var fileTypes = ["gif", "jpg", "jpeg", "png"];
      for (let i = 0; i < targetFiles.length; i++) {
        if (fileTypes.includes(targetFiles[i].name.split(".")[1])) {
          // 获取token上传七牛云
          getUploadToken({
            fileName: evfile.target.files[i].name
          }).then(
            res => {
              var uptoken = res.data.token;
              var file = evfile.target.files[i]; // Blob 对象，上传的文件
              var key = res.data.fid; // 上传后文件资源名以设置的 key 为主，如果 key 为 null 或者 undefined，则文件资源名会以 hash 值作为资源名。

              const config = {
                useCdnDomain: true, // 表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false。
                region: qiniu.region.z0 // 根据具体提示修改上传地区,当为 null 或 undefined 时，自动分析上传域名区域
              };

              const putExtra = {
                fname: "", // 文件原文件名
                params: {}, // 用来放置自定义变量
                mimeType: null // 用来限制上传文件类型
              };
              var observable = qiniu.upload(
                file,
                key,
                uptoken,
                putExtra,
                config
              );
              observable.subscribe({
                next: result => {
                  // 主要用来展示进度
                  // console.log(result);
                },
                error: errResult => {
                  // 失败报错信息
                  // console.log(errResult);
                },
                complete: result => {
                  // 接收成功后返回的信息
                  // console.log(result);
                  // 从后台获取图片显示
                  // obj.push(result.key)
                  resolve(result.key)
                }
              });

            }
          );

        } else {
          this.$message.error("您选中的某个类型不支持上传,请重新选择");
        }

      }
    });
  },

  // 上传文件
  fileUpdate: function (evfile) {
    return new Promise((resolve, reject) => {
      var targetFiles = evfile.target.files;
      var fileTypes = ["pdf"];
      for (let i = 0; i < targetFiles.length; i++) {
        if (fileTypes.includes(targetFiles[i].name.split(".")[1])) {
          // 获取token上传七牛云
          getUploadToken({
            fileName: evfile.target.files[i].name
          }).then(
            res => {
              var uptoken = res.data.token;
              var file = evfile.target.files[i]; // Blob 对象，上传的文件
              var key = res.data.fid; // 上传后文件资源名以设置的 key 为主，如果 key 为 null 或者 undefined，则文件资源名会以 hash 值作为资源名。
              var fileDomain =res.data.fileDomain
              const config = {
                useCdnDomain: true, // 表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false。
                region: fileDomain // 根据具体提示修改上传地区,当为 null 或 undefined 时，自动分析上传域名区域
              };
             

              const putExtra = {
                fname: "", // 文件原文件名
                params: {}, // 用来放置自定义变量
                mimeType: null // 用来限制上传文件类型，为 null 时表示不对文件类型限制；限制类型放到数组里： ["image/png", "image/jpeg", "image/gif"]
              };
              var observable = qiniu.upload(
                file,
                key,
                uptoken,
                putExtra,
                config
              );
              observable.subscribe({
                next: result => {
                  // 主要用来展示进度
                  // console.log(result);
                },
                error: errResult => {
                  // 失败报错信息
                  // console.log(errResult);
                },
                complete: result => {
                  // 接收成功后返回的信息
                  // console.log(result);
                  // 从后台获取图片显示
                  // obj.push(result.key)
                  resolve(result.key)
                }
              });

            }
          );

        } else {
          this.errorTip("失败","请选择上传pdf格式的文档");
        }

      }
    });
  },


  // 时间转化
  time: function (val) {
    var date = new Date(val) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var Y = date.getFullYear() + '-'
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
    var D = date.getDate() + ' '
    var h = date.getHours() + ':'
    var m = date.getMinutes()
    // var s = date.getSeconds()
    return Y + M + D + h + m
  },
  // 标准时间转化
  dateConvert: function (date) {
    var dateBefore = new Date(date);
    let dateAfter =
      dateBefore.getFullYear() +
      "-" +
      (dateBefore.getMonth() + 1 < 10 ?
        "0" + (dateBefore.getMonth() + 1) :
        dateBefore.getMonth() + 1) +
      "-" +
      (dateBefore.getDate() < 10 ?
        "0" + dateBefore.getDate() :
        dateBefore.getDate()) +
      " " +
      (dateBefore.getHours() < 10 ?
        "0" + dateBefore.getHours() :
        dateBefore.getHours()) +
      ":" +
      (dateBefore.getMinutes() < 10 ?
        "0" + dateBefore.getMinutes() :
        dateBefore.getMinutes());
    return dateAfter;
  },
  // elementUI的操作后提示
  successTip: function (title, message, dir) {
    Notification.success({
      title: title,
      message: message,
      position: dir
    });
  },
  errorTip: function (title, message, dir) {
    Notification.error({
      title: title,
      message: message,
      position: dir
    });
  },
  // messageBox封装(删除等二次操作确定)
  handleCofirm(text = '确定执行此操作吗？', type = 'warning') {
    return MessageBox.confirm(text, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: type,
    })
  },
  // 校验判断
  // num>0的正整数
  inputNum: function (val) {
    let reg = /^\+?[1-9]\d*$/;
    if (reg.test(val)) {
      return true;
    } else {
      return false;
    }
  },
  // 
  // phone的
  phoneNum: function (val) {
    if (!/^1[3456789]\d{9}$/.test(val)) {
      return false;
    } else {
      return true;
    }
  }
 
}
