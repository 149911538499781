import request  from './request'

// 登录封装
// export const apiLogin = p => post('/auth/login', p)
export function apiLogin(data) {
  return request({
    url: '/auth/login',
    method: 'post',
    data
  })
}
//修改密码

//获取用户列表
export function getuserlist(params) {
    return request({
      url: `/userInfo/list`,
      params
    })
  }

  export function getauserlist(params) {
    return request({
      url: `/userInfo/list/${params.companyId}`,
      params
    })
  }
  // 添加用户
  export function adduser(data) {
    return request({
      url: '/userInfo',
      method: 'post',
      data
    })
  }
  
  // 编辑用户
  export function updateuser(data) {
    return request({
      url: `/userInfo/${data.userId}`,
      method: 'put',
      data
    })
}
  // 删除用户

export function deluser(userId) {
    return request({
      url: `/userInfo/${userId}`,
      method: 'delete',
    
    })
  }
   // 设置用户
   export function setuser(userId) {
    return request({
      url: `/userInfo/accountStatus/${userId}`,
      method: 'put',
     
    })
  }
//设置后台登录密码


export function setPassword(data) {
  return request({
    url: '/auth/setPassword',
    method: 'post',
    data
  })
}

// 操作日志

export function getLogList(params) {
    return request({
      url: '/log/list',
      params
    })
  }
//七牛云

export function getUploadToken(params) {
  return request({
    url: '/qiniu/getUploadToken',
    params
  })
}

//牧区


//获取全部
export function getallarealist() {
  return request({
    url: '/area/list',
   
  })
}

//地区下的合同
export function areaPageList(params) {
  return request({
    url: '/contractInfo/areaPageList',
    params
  })
}





//获取牧区信息
export function getarealist(params) {
  return request({
    url: '/area/pageList',
    params
  })
}

// 添加牧区
export function addarea(data) {
  return request({
    url: '/area',
    method: 'post',
    data
  })
}

// 编辑牧区
export function updatearea(data) {
  return request({
    url: `/area/${data.areaId}`,
    method: 'put',
    data
  })
}
// 删除牧区

export function delarea(areaId) {
  return request({
    url: `/area/${areaId}`,
    method: 'delete',
  
  })
}

//企业
//全部企业
export function getallcompany(params) {
  return request({
    url: '/companyInfo/list',
    params
  })
}







//获取企业
export function getcompany(params) {
  return request({
    url: '/companyInfo/pageList',
    params
  })
}
export function companyInfo(companyId) {
  return request({
    url: `/companyInfo/${companyId}`,
  
  
  })
}

// 添加企业
export function addcompany(data) {
  return request({
    url: '/companyInfo',
    method: 'post',
    data
  })
}

// 编辑企业
export function updatecompany(data) {
  return request({
    url: `/companyInfo/${data.companyId}`,
    method: 'put',
    data
  })
}
// 删除企业

export function delcompany(companyId) {
  return request({
    url: `/companyInfo/${companyId}`,
    method: 'delete',
  
  })
}


//合同
//获取合同
export function getcontractInfo(params) {
  return request({
    url: `/contractInfo/pageList/${params.companyId}`,
    params
  })
}
//获取合同详情
export function getcontractInfoDetail(contractId) {
  return request({
    url: `/contractInfo/${contractId}`,
   
  })
}
// 添加合同
export function addcontractInfo(data) {
  return request({
    url: '/contractInfo',
    method: 'post',
    data
  })
}
// 结束合同
export function setcontractInfo(contractId) {
  return request({
    url: `/contractInfo/endContract/${contractId}`,
    method: 'post',
   
  })
}
//获取审核列表
export function pageList(params) {
  return request({
    url: `/contractReview/pageList/${params.status}`,
    params
    
   
  })
}
//进行审核

export function reviewContract(data) {
  return request({
    url: `/contractReview/reviewContract/${data.recordId}`,
    method: 'post',
    data
   
  })
}

//审核结果列表

export function reviewResult(contractId) {
  return request({
    url: `/contractReview/reviewResults/${contractId}`,
  
   
  })
}

// 提交审核合同
export function contractReview(contractId) {
  return request({
    url: `/contractReview/${contractId}`,
    method: 'post',
   
  })
}
//合同操作记录

export function contractLog(contractId) {
  return request({
    url: `/contractLog/pageList/${contractId}`,
   
   
  })
}

// 编辑合同
export function updatecontractInfo(data) {
  return request({
    url: `/contractInfo/${data.contractId}`,
    method: 'put',
    data
  })
}
// 删除合同

export function delcontractInfo(contractId) {
  return request({
    url: `/contractInfo/${contractId}`,
    method: 'delete',
  
  })
}
//订单操作记录
export function getOrderLogList(orderId) {
  return request({
    url: `/orderEvent/list/${orderId}`,
   
  })
}
//生长记录
//获取生长记录
export function growRecordList(data) {
  return request({
    url: `/bmsGrowRecord/growRecordList`,
    method: 'post',
    data
   
  })
}





//添加
export function addgrowRecord(data) {
  return request({
    url: '/bmsGrowRecord',
    method: 'post',
    data
  })
}




//编辑
export function updategrowRecord(data) {
  return request({
    url: `/bmsGrowRecord/${data.id}`,
    method: 'put',
    data
  })
}


//删除
export function delgrowRecord(data) {
  return request({
    url: `/bmsGrowRecord/`,
    method: 'delete',
    data
  
  })
}
//销售记录
//获取销售记录
export function saleRecordList(data) {
  return request({
    url: `/bmsSaleRecord/saleRecordList`,
    method: 'post',
    data
   
  })
}





//添加
export function addsaleRecord(data) {
  return request({
    url: '/bmsSaleRecord',
    method: 'post',
    data
  })
}




//编辑
export function updatesaleRecord(data) {
  return request({
    url: `/bmsSaleRecord/${data.id}`,
    method: 'put',
    data
  })
}


//删除
export function delsaleRecord(data) {
  return request({
    url: `/bmsSaleRecord`,
    method: 'delete',
    data
  
  })
}
//客服

export function getPhone(params) {
  return request({
    url: '/CustomerHelper/getPhone',
    params
  })
}

export function addphone(data) {
  return request({
    url: '/CustomerHelper',
    method: 'post',
    data
  })
}

export function updatePhone(data) {
  return request({
    url: '/CustomerHelper/updatePhone',
    method: 'put',
    data
  })
}
//角色

export function getrole(params) {
  return request({
    url: '/role/list',
    params
  })
}

//获取轮播图
export function getswiper() {
  return request({
    url: '/home',

  })
}
//添加

export function addswiper(data) {
  return request({
    url: `/home`,
    method: 'post',
    data
  })
}
//删除

export function delswiper(id) {
  return request({
    url: `/home/${id}`,
    method: 'delete',
  
  })
}
//首页数据

export function getmsg() {
  return request({
    url: '/home/homeInfo',

  })
}



export function gehtfid(contractId) {
  return request({
    url: `/contractInfo/contractFileList/${contractId}`,
    method: 'post',
 
  })
}