import Vue from 'vue'
import App from './App.vue'
import router from './router'
import * as filters from './filters' // 引入工具类
import 'normalize.css/normalize.css' // A modern alternative to CSS resets
import '@/styles/index.scss' // global css
import '../permission'
import store from './store'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
import './static/css/global.css' /*引入公共样式*/

import preventReClick from './util/preventRepeatClick'
import './util/moment'
import Component from '@/components'
import './icons' // icon
import md5 from 'js-md5';
Vue.prototype.$md5 = md5;
Vue.use(preventReClick)
import util from './util2/util'
import SvgIcon from './components/SvgIcon'// svg component

// register globally
Vue.component('svg-icon', SvgIcon)
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)

// 或者你可以自己设置全局注册的组件名 默认注册的组件名是 vue-seamless-scroll
Vue.use(scroll,{componentName: 'scroll-seamless'})

const req = require.context('./icons/svg', false, /\.svg$/)
const requireAll = requireContext => requireContext.keys().map(requireContext)
requireAll(req)
Vue.prototype.util = util
Vue.config.productionTip = false
Vue.prototype.img = 'http://farmfile.uutele.com/'
Vue.use(Component) // 注册自己的插件
Object.keys(util).forEach(key => Vue.filter(key, util[key]))
//注册全局的过滤器
Object.keys(filters).forEach(key => {
  // 注册过滤器
  Vue.filter(key, filters[key])
})

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
