import axios from "axios";
import Cookies from 'js-cookie'
import store from '../store'
import {
  getTimeStamp
} from '../util/auth.js'
import router from '../router'
// import QS from 'qs'
// 引入进度条插件
import { Message, MessageBox } from "element-ui";
const TimeOut = 86400 // 定义超时时间86400
axios.defaults.timeout = 5000;

// axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'


// axios.defaults.baseURL = 'http://192.168.2.165:8090/webV2';
axios.defaults.baseURL = 'http://farm.uutele.com/webV2';




axios.interceptors.request.use(
  config => {
    const token = Cookies.get('token');
    if (token) {
      // config.headers['Authorization'] = 'Bearer ' + store.getters.token
      // 有token 判断token是否超时
      if (IsCheckTimeOut()) {
        // 退出登录
        store.dispatch('user/logout')
        // 跳转到登录页面tangyan
        router.push('/login')
        return Promise.reject(new Error('登录超时'))
      }
      config.headers.token = token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  },
)

axios.interceptors.response.use(
  (response) => {
    if (response.data.code == 11) {
      store.dispatch('user/logout')
      // 跳转到登录页面
      router.push('/login')
     }
    
    return response;
  },
  // 被动处理token失效

  (error) => {
    
    if (error.response.code==11) {
      // token 超时，登出，跳转
      // 退出登录
      store.dispatch('user/logout')
      // 跳转到登录页面
      router.push('/login')
    } else {
      Message.error(error.response.message)
    }
    return Promise.reject(error)

  }
);



function IsCheckTimeOut() {
  // 当前时间戳
  var currentTime = Date.now()
  // 缓存中的时间戳
  var timeStamp = getTimeStamp()
  // 时间差
  console.log((currentTime - timeStamp)/ 1000) ;
  return (currentTime - timeStamp) / 1000 > TimeOut
}
export default axios