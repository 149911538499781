<template>
  <div>
    <div class="userInfo">
      <img
        class="userInfo_change"
        @click="changeScreen"
        :src="isFull ? require('../assets/changeSmall.png') : require('../assets/changeBig.png')"
        alt=""
      />
      <img class="userInfo_img" src="../assets/timg.jpg" alt="" />
     
      <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          {{ username }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item  @click.native="setpassword">修改密码</el-dropdown-item>
          <el-dropdown-item @click.native="outLogin">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
     <el-dialog
      :title="pws"
      :visible="showlog"
      @close="btnCancel1"
    >
      <!-- 表单 -->
      <el-form
        ref="pForm"
        :rules="rules1"
       :model="formdata"
        label-width="120px"
      >
        <el-form-item label="登录密码" prop="password">
          <el-input
            :key="passwordType"
            ref="password"
            v-model="formdata.password"
            :type="passwordType"
            @change='change'
            placeholder="请输入密码"
            name="password"
            tabindex="2"
            auto-complete="on"
            minlength="6"
            maxlength="16"
            style="width:200px"
          />
          <span class="show-pwd" @click="showPwd" style="margin-left:8px">
            <svg-icon
              :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'"
            />
          </span>
        </el-form-item>
      </el-form>
      <el-row slot="footer" type="flex" justify="center">
        <el-col :span="6">
          <el-button size="small" type="primary" @click="btnOK1">
            确定
          </el-button>
          <el-button size="small" @click="btnCancel1">取消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import {
setPassword
} from "../request/http";
export default {
  data() {
    return {
        pws: '设置登录密码',
      showlog: false,
      passwordType: 'password',
      formdata:{
      password: '',
      },
     
      username: "",
      isFull: false,
      rules1: {
        password: [
           {
            pattern:
              /^[A-Za-z0-9]\w{5,17}$/,
            message: "密码长度不够",
          },
          { required: true, message: '密码不能为空', trigger: 'blur' },
        ]
      }
    };
  },
  created() {
    this.username =  localStorage.getItem('username');
    console.log(  this.username);
  },
  mounted() {
    // this.username =  localStorage.getItem('userName');
 
  },
  methods: 
  {
    showPwd() {
      if (this.passwordType == 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    change(val){
   this.formdata.password =val
    },
     btnOK1() {
           setPassword({
            password: this.$md5(this.formdata.password .trim())
            // password: this.password.trim()
          }).then(res=>{
            if(res.code==0){
            this.$message.success('设置成功')
          // this.showlog = false
          }
           else {
          this.$message.warning(res.msg)
        }
          })
      this.showlog = false
        this.$alert("请重新登录", "提示", {
        confirmButtonText: 'OK',
        type: "warning",
      }).then(() => {
        this.$store.dispatch("LogOut").then(() => {
          console.log(1);
           location.href='http://farm.uutele.com/'
       
     
        });
      }).catch(error=>{
        console.log(error);
      })
        
    },
    // 弹窗取消函数
    btnCancel1() {
     this.formdata.password  = ''
      this.$refs.pForm.resetFields()
      this.showlog = false
    },
    changeScreen() {
      var docElm = document.documentElement;
      if (!this.isFull) {
        this.isFull=true
        if (docElm.requestFullscreen) {
          docElm.requestFullscreen();
        }

        //FireFox
        else if (docElm.mozRequestFullScreen) {
          docElm.mozRequestFullScreen();
        }

        //Chrome等
        else if (docElm.webkitRequestFullScreen) {
          docElm.webkitRequestFullScreen();
        }

        //IE11
        else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen();
        }
      } else {
        this.isFull=false
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
        //FireFox
        else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        }
        //Chrome等
        else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
        //IE11
        else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }
    },
    setpassword(){
   this.showlog = true
    },
    outLogin(){
      this.$confirm("确定注销并退出系统吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
         this.$store.dispatch("LogOut").then(() => {
          console.log(1);
           location.href='http://farm.uutele.com/'
       
     
        });
      }).catch(error=>{
        console.log(error);
      })
    },
  },
};
</script>
<style>
.userInfo {
  display: flex;
  align-items: center;
  padding-right: 30px;
}
.userInfo_img {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  margin-right: 15px;
}
.userInfo_change {
  margin-right: 15px;
  width: 25px;
  height: 25px;
}
.el-dropdown-link{
  cursor: pointer;
}
</style>