var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"EditorItem"},[_c('vue-ueditor-wrap',{attrs:{"config":_vm.myConfig},on:{"beforeInit":_vm.addCustomButtom},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}}),_c('el-dialog',{attrs:{"title":"图片上传","visible":_vm.dialogVisible1,"width":"40%","append-to-body":"","lock-scroll":true,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.dialogVisible1=$event}}},[_c('div',{staticClass:"image-upload-panel"},[_vm._l((_vm.imageList),function(item,index){return _c('div',{key:index,staticClass:"image-item"},[_c('img',{attrs:{"width":"100%","height":"100%","src":_vm.imgurl + item}}),_c('span',{staticClass:"image-del el-icon-close",on:{"click":function($event){return _vm.delImage(index)}}})])}),_c('div',[_c('el-button',{staticClass:"image-upload-btn",on:{"click":_vm.zh_uploadFile}},[_c('i',{staticClass:"el-icon-plus"})]),_c('input',{ref:"evfile",staticStyle:{"display":"none"},attrs:{"type":"file","multiple":""},on:{"change":_vm.zh_uploadFile_change,"click":function (event) {
              event.target.value = null;
            }}})],1)],2),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function () {
            this$1.dialogVisible1 = false;
          }}},[_vm._v(" 取 消 ")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.insertImage}},[_vm._v("确 定")])],1)]),_c('el-dialog',{attrs:{"title":"视频上传","visible":_vm.dialogVisible,"width":"30%"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('el-button',{on:{"click":_vm.zh_uploadFile1}},[_vm._v("点击上传")]),_c('p',{staticStyle:{"margin":"10px 0 0 10px"}},[_vm._v("文件: "+_vm._s(_vm.videoShow))]),_c('p',{staticStyle:{"margin":"10px 0 0 10px"}},[_vm._v("进度: "+_vm._s(_vm.processVideo))]),_c('input',{ref:"evfile1",staticStyle:{"display":"none"},attrs:{"type":"file","multiple":""},on:{"change":_vm.zh_uploadFile_change1,"click":function (event) {
          event.target.value = null;
        }}}),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){return _vm.cancelVedio()}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.insertVideo}},[_vm._v("确 定")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }