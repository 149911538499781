import {
  getToken,
  setToken,
  removeToken,
  setTimeStamp
} from '@/util/auth'
import {
  apiLogin,
} from '@/request/http'

const state = {
  token: getToken(),
  userInfo: {}
}
const mutations = {
  setToken(state, token) {
    state.token = token // 将数据设置给vuex
    setToken(token) // 同步给缓存
  },
  removeToken(state) {
    state.token = null // 将vuex的数据置空
    removeToken() // 清除缓存
  },
  setUserInfo(state, result) {
    state.userInfo = result
  },
  removeUserInfo(state) {
    state.userInfo = {}
  }

}
const actions = {
  async login(context, res) {
    // 经过响应拦截器的处理之后 这里的result实际上就是 token
    const {
      data
    } = await apiLogin(res) // 实际上就是一个promise  result就是执行的结果
    console.log(data.data)
    const Base64 = require('js-base64').Base64
    const obj = Base64.decode(data.data.token.split('.')[1])
    const userInfo = JSON.parse(obj)
    const userObj = JSON.parse(userInfo.userInfo)
  
    // axios默认给数据加了一层data
    // 表示登录接口调用成功 也就是意味着你的用户名和密码是正确的
    // 现在有用户token
    // actions 修改state 必须通过mutations
    localStorage.setItem('role',userObj.role)
    localStorage.setItem('companyId',userObj.companyId)
    localStorage.setItem('areaId',userObj.areaId)
    localStorage.setItem('username',userObj.username)
   
    context.commit('setToken', data.data.token) // 设置token
    context.commit('setUserInfo', userObj)
       console.log(state.userInfo);
    setTimeStamp() // 设置时间戳
  },

  // 登出的action
  logout(context) {
    // 删除token
    context.commit('removeToken') // 
    context.commit('removeUserInfo') // 删除用户信息
    localStorage.removeItem('menuList')
    localStorage.removeItem('role')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}