// vuex的权限模块
// import { asyncRoutes, constantRoutes } from '@/router'
import Layout from "@/layout/layout.vue";
import store from '@/store'

// vuex中的permission模块用来存放当前的 静态路由 + 当前用户的 权限路由
var sroute =[
  {
      path: "/area",
      meta: { name: "地区管理", icon: "dashboard" },
      component: Layout,
    
      children: [
        {
          path: "/area",
          meta: { name: "地区列表", icon: "excel" },
          component: "Layout",
        },
      
      ]

    },
 
    {
      path: "/company",
      meta: { name: "企业管理", icon: "message" },
      component: Layout,
      children: [
        {
          path: "/company",
          meta: { name: "企业列表", icon: "lock" }, 
          component: "Layout",
        },
      ]
    },

    {
      path: '/contractList',
      meta: { name: "合同管理", icon: "guide" },
      component: Layout,
      children: [
        {
          path: "/contractList",
          meta: { name: "合同列表", icon: "list" },
          component: "Layout",
        },
  
      ]
    },
    {
      path: '/rotationChart',
      meta: { name: "用户管理", icon: "people" },
      component: "Layout",
      children: [
        {
          path: "/rotationChart",
          meta: { name: "用户列表", icon: "peoples" },
          component: "Layout",
        },
      
      ]
    },
   
    {
      path: "/phone",
      meta: { name: "客服管理", icon: "skill" },
      component: Layout,
      
      children: [
        {
          path: "/phone",
          meta: { name: "客服电话", icon: "wechat" },
          component: "Layout",
        },
      
      ]
    },
    {
      path: "/ulog",
      meta: { name: "操作日志", icon: "setting" },
      component: Layout,
      children:[
        {
          path: "/ulog",
          meta: { name: "操作日志", icon: "list" },
          component: "Layout",
        },
       
       
        
      ]
    },





]


var rtroutes =[
    {
        path: "/area",
        meta: { name: "地区管理", icon: "dashboard" },
        component: Layout,
      
        children: [
          {
            path: "/area",
            meta: { name: "地区列表", icon: "excel" },
            component: "Layout",
          },
        
        ]
  
      },
   
      {
        path: "/company",
        meta: { name: "企业管理", icon: "message" },
        component: Layout,
        children: [
          {
            path: "/company",
            meta: { name: "企业列表", icon: "lock" }, 
            component: "Layout",
          },
        ]
      },
      {
        path: '/contractList',
        meta: { name: "合同管理", icon: "guide" },
        component: Layout,
        children: [
          {
            path: "/contractList",
            meta: { name: "合同列表", icon: "list" },
            component: "Layout",
          },
    
        ]
      },
      {
        path: '/rotationChart',
        meta: { name: "用户管理", icon: "people" },
        component: "Layout",
        children: [
          {
            path: "/rotationChart",
            meta: { name: "用户列表", icon: "peoples" },
            component: "Layout",
          },
        
        ]
      },
     
      {
        path: "/phone",
        meta: { name: "客服管理", icon: "skill" },
        component: Layout,
        
        children: [
          {
            path: "/phone",
            meta: { name: "客服电话", icon: "wechat" },
            component: "Layout",
          },
        
        ]
      },
    


 
  
]
var  rroutes = [
   
  // {
  //   path: '/companyinfo',
  //   meta: { name: "企业信息", icon: "message" },
  //   component: Layout,
  //   children: [
  //     {
  //       path: "/companyinfo",
  //       meta: { name: "企业信息", icon: "table" },
  //       component: "Layout",
  //     },

  //   ]
  // },
  {
    path: "/Overview",
    meta: { name: "首页", icon: "dashboard" },
    component: "Layout",
    children:[
      {
        path: "/Overview1",
        meta: { name: "数据大屏", icon: "excel" },
        component: "Layout",
      },
    ]},
    {
      path: '/swiper',
      meta: { name: "首页轮播", icon: "message" },
      component: Layout,
      children: [
        {
          path: "/swiper",
          meta: { name: "轮播列表", icon: "table" },
          component: "Layout",
        },
  
      ]
    },
  
  {
    path: '/contractList',
    meta: { name: "合同管理", icon: "guide" },
    component: Layout,
    children: [
      {
        path: "/contractList",
        meta: { name: "合同列表", icon: "list" },
        component: "Layout",
      },

    ]
  },
  {
    path: '/user',
    meta: { name: "人员管理", icon: "people" },
    component: Layout,
    children: [
      {
        path: "/user",
        meta: { name: "人员列表", icon: "list" },
        component: "Layout",
      },

    ]
  },
      
     
     



]
var  audit = [


  {
    path: '/enterprise',
    meta: { name: "企业列表", icon: "message" },
    component: Layout,
    children: [
      {
        path: "/enterprise",
        meta: { name: "企业信息", icon: "table" },
        component: "Layout",
      },

    ]
  },

  {
    path: '/part',
    meta: { name: "合同列表", icon: "guide" },
    component: Layout,
    children: [
      {
        path: "/part",
        meta: { name: "合同信息", icon: "list" },
        component: "Layout",
      },

    ]
  },
  {
    path: "/audit",
    meta: { name: "审核管理", icon: "message" },
    component: Layout,
  
    children: [
      {
        path: "/audit",
        meta: { name: "审核列表", icon: "list" },
        component: "Layout",
      },
  
    ]
  },
 

]


const state = {
    
  menuList: null // 所有人默认拥有静态路由
}
const mutations = {
  // newRoutes可以认为是 用户登录 通过权限所得到的动态路由的部分
  setRoutes(state, Routes) {
    // 应该是每次更新 都应该在静态路由的基础上进行追加
    state.menuList = [...Routes,]
  }
}
const actions = {

 async getRoutes(context) {
   console.log(localStorage.getItem('role'));
  //  context.commit('setRoutes', rtroutes)
  //    localStorage.setItem('menuList', JSON.stringify(rtroutes))
  //   return  state. menuList

  //超管
  if(localStorage.getItem('role')==100){
    context.commit('setRoutes', sroute)
   
    localStorage.setItem('menuList', JSON.stringify(sroute))
 return  state. menuList
 }
   //平台管理员
    if(localStorage.getItem('role')==0){
       context.commit('setRoutes', rtroutes)
      
       localStorage.setItem('menuList', JSON.stringify(rtroutes))
    return  state. menuList
    }
    //企业管理员
    else if (localStorage.getItem('role')==1){
      context.commit('setRoutes', rroutes)
      localStorage.setItem('menuList', JSON.stringify(rroutes))
      return state. menuList
    }
    //审核人员
    else if (localStorage.getItem('role')==3||localStorage.getItem('role')==4){
      context.commit('setRoutes', audit)
      localStorage.setItem('menuList', JSON.stringify(audit))
      return state. menuList
    }
   
  }
}


export default {
  namespaced: true,
  state,
  mutations,
  actions
}