
import Pagination from './Pagination'
import UEditor from './item/item.vue'

export default {
  install(Vue) {
    Vue.component('uEditor',UEditor)
    Vue.component('pagination', Pagination)
   
  }
}
