// import Vue from "vue";
// import Router from "vue-router";
// import Layout from "../layout/layout.vue";
// // 使用VueRouter
// Vue.use(Router);

// // //获取原型对象上的push函数
// // const originalPush = Router.prototype.push;
// // Router.prototype.push = function push(location) {
// //   return originalPush.call(this, location).catch((err) => err);
// // };
// // const originalReplace = Router.prototype.replace;

// // Router.prototype.replace = function replace (location, onResolve, onReject) {
// //   // if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
// //   return originalReplace.call(this, location).catch(err => err)
// // }
// export const constantRoutes = [
//   // 登录页

//   { path: '/', redirect: '/login' },
//   { path: '/login', name: 'login', component: () => import('../init/login.vue') },

//   {
//     path: "/area",
//     meta: { name: "地区管理", icon: "el-icon-s-home" },
//     component: Layout,
//     children: [

//       {
//         path: "",
//         meta: { name: "地区列表", icon: "list" },
//         component: () => import('../views/area/area.vue')
//       },

//       // 合同
//       {
//         path: "/contract",
//         name: 'contract',
//         component: () => import('../views/orderManagement/contract.vue'),
//       },
//       // 添加订单
//       {
//         path: "/addOrder",
//         name: 'addOrder',
//         component: () => import('../views/orderManagement/addOrder.vue'),
//       },
//       // 编辑订单
//       {
//         path: "/editorOrder",
//         name: 'editorOrder',
//         component: () => import('../views/orderManagement/editorOrder.vue'),
//       },
//       // 订单详情页面
//       {
//         path: "/orderDetails",
//         name: 'orderDetails',
//         component: () => import('../views/orderManagement/orderDetails.vue'),
//       },

//       //  // 收购
//       {
//         path: "/record",
//         name: 'record',
//         component: () => import('../views/orderManagement/shopping.vue'),
//       },

//       {
//         path: "/purchase",
//         name: 'purchase',
//         component: () => import('../views/orderManagement/purchase.vue'),
//       },
//     ],
//   },

//   {
//     path: "/ulog",
//     meta: { name: "操作日志", icon: "setting" },
//     component: Layout,
//     children: [
//       {
//         path: "",
//         meta: { name: "操作日志", icon: "list" },
//         component: () => import('../views/ULog/ULog.vue')
//       },

//     ]
//   },
//   //人员管理
//   {
//     path: '/rotationChart',
//     meta: { name: "用户管理", icon: "el-icon-s-home" },
//     component: Layout,
//     children: [
//       {
//         path: "",
//         meta: { name: "用户列表", icon: "list" },
//         component: () => import('../views/user/rotationChart.vue')
//       },

//     ]
//   },
//   {
//     path: '/role',
//     meta: { name: "角色管理", icon: "el-icon-s-home" },
//     component: Layout,
//     children: [
//       {
//         path: "",
//         meta: { name: "角色列表", icon: "list" },
//         component: () => import('../views/role/index.vue')
//       },

//     ]
//   },
//   {
//     path: "/company",
//     meta: { name: "企业管理", icon: "user" },
//     component: Layout,
//     children: [
//       {
//         path: "",
//         meta: { name: "企业列表", icon: "user" },
//         component: () => import('../views/company/index.vue')
//       },
//     ]
//   },

//   // 合同管理
//   // {
//   //   path: "/orderManagement",
//   //   meta: { name: "合同管理", icon: "el-icon-s-home" },
//   //   component: Layout,

//   //   children: [
//   //     {
//   //       path: "",
//   //       meta: { name: "合同列表", icon: "list" },
//   //       component: () => import('../views/orderManagement/orderManagement.vue'),
//   //     },

//   //   ]
//   // },
//   //客服
//   {
//     path: "/phone",
//     meta: { name: "客服管理", icon: "el-icon-s-home" },
//     component: Layout,

//     children: [
//       {
//         path: "",
//         meta: { name: "客服电话", icon: "list" },
//         component: () => import('../views/phone/index.vue'),
//       },

//     ]
//   },



// ];

// export default new Router({
//   mode: "hash",
//   scrollBehavior: () => ({ y: 0 }),
//   routes: constantRoutes,
// });







import Vue from "vue";
import Router from "vue-router";
import Layout from "../layout/layout.vue";
// 使用VueRouter
Vue.use(Router);

//获取原型对象上的push函数
// const originalPush = Router.prototype.push;
// Router.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch((err) => err);
// };
// const originalReplace = Router.prototype.replace;

// Router.prototype.replace = function replace (location, onResolve, onReject) {
//   // if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
//   return originalReplace.call(this, location).catch(err => err)
// }
export const constantRoutes = [
  // 登录页

  { path: '/', redirect: '/login' },
  { path: '/login', name: 'login', component: () => import('../init/login.vue') },
  { path: '/Overview1',    meta: { name: "数据大屏"}, component: () => import('../views/Overview/Overview1.vue') },

  {
    path: "/Overview",
    meta: { name: "首页", icon: "dashboard" },
    component: Layout,
    children: [
      {
        path: "",
        meta: { name: "数据概览", icon: "excel" },
        hidden:'true',
        component: () => import('../views/Overview/Overview.vue')
      },
      {
        path: "/home",
        meta: { name: "数据大屏", icon: "excel" },
        component: () => import('../views/Overview/Overview1.vue')
      },
    ]
  },
  {
    path: '/swiper',
    meta: { name: "首页轮播", icon: "el-icon-s-home" },
    component: Layout,
    children: [
      {
        path: "",
        meta: { name: "轮播列表", icon: "list" },
        component: () => import('../views/swiper/index.vue')
      },

    ]
  },

  {
    path: "/area",
    // redirect:'/area',
    meta: { name: "地区管理", icon: "dashboard" },
    component: Layout,
    children: [
      {
        path: "",
        meta: { name: "地区列表", icon: "list" },
        component: () => import('../views/area/area.vue')
      },
 
   
    ]
  },
  {
    path: '/contractList',
    meta: { name: "合同管理", icon: "el-icon-s-home" },
    component: Layout,
    children: [
      {
        path: "",
        meta: { name: "合同列表", icon: "list" },
        component: () => import('../views/order/index.vue')
      },

    ]
  },

  {
    path: "/ulog",
    meta: { name: "操作日志", icon: "setting" },
    component: Layout,
    children: [
      {
        path: "",
        meta: { name: "操作日志", icon: "list" },
        component: () => import('../views/ULog/ULog.vue')
      },

    ]
  },
  //人员管理
  {
    path: '/rotationChart',
    meta: { name: "用户管理", icon: "el-icon-s-home" },
    component: Layout,
    children: [
      {
        path: "",
        meta: { name: "用户列表", icon: "list" },
        component: () => import('../views/user/rotationChart.vue')
      },

    ]
  },
  {
    path: '/user',
    meta: { name: "用户管理", icon: "el-icon-s-home" },
    component: Layout,
    children: [
      {
        path: "",
        meta: { name: "用户列表", icon: "list" },
        component: () => import('../views/user/user.vue')
      },

    ]
  },
  {
    path: '/companyinfo',
    meta: { name: "企业信息", icon: "el-icon-s-home" },
    component: Layout,
    children: [
      {
        path: "",
        meta: { name: "企业信息", icon: "list" },
        component: () => import('../views/company/company.vue')
      },

    ]
  },
  {
    path: '/enterprise',
    meta: { name: "企业列表", icon: "el-icon-s-home" },
    component: Layout,
    children: [
      {
        path: "",
        meta: { name: "企业信息", icon: "list" },
        component: () => import('../views/enterprise/index.vue')
      },

    ]
  },

  {
    path: '/part',
    meta: { name: "合同列表", icon: "el-icon-s-home" },
    component: Layout,
    children: [
      {
        path: "",
        meta: { name: "合同信息", icon: "list" },
        component: () => import('../views/part/index.vue')
      },

    ]
  },






  {
    path: "/company",
    meta: { name: "企业管理", icon: "user" },
    component: Layout,
    children: [
      {
        path: "",
        meta: { name: "企业列表", icon: "user" },
        component: () => import('../views/company/index.vue')
      },
        // 合同
     {
      path: "/contract",
      name: 'contract',
      component: () => import('../views/orderManagement/contract.vue'),
    },
  
    // 订单详情页面
    {
      path: "/orderDetails",
      name: 'orderDetails',
      component: () => import('../views/orderManagement/orderDetails.vue'),
    },

    //合同详情
    {
      path: "/contractInfo",
      name: 'contractInfo',
      meta: { name: "合同详情" },
      component: () => import('../views/orderManagement/contractInfo.vue'),
    },
    //生长
    {
      path: "/growth",
      name: 'growth',
      component: () => import('../views/growth/index.vue'),
    },
    //销售

    {
      path: "/sale",
      name: 'sale',
      component: () => import('../views/sale/index.vue'),
    },
    //合同审核
    {
      path: "/contractReview",
      name: 'contractReview',
      meta: { name: "合同详情" },
      component: () => import('../views/orderManagement/contractReview.vue'),
    },
    {
      path: "/review",
      name: 'review',
      component: () => import('../views/orderManagement/review.vue'),
    },
    
    

    //  // 收购
    {
      path: "/record",
      name: 'record',
      component: () => import('../views/orderManagement/shopping.vue'),
    },

    {
      path: "/addcompany",
      name: 'addcompany',
      component: () => import('../views/company/addcompany.vue'),
    },
    
    {
      path: "/companyuser",
      name: 'companyuser',
      component: () => import('../views/company/user.vue'),
    },
    ]
  },

  // 合同管理
  // {
  //   path: "/orderManagement",
  //   meta: { name: "合同管理", icon: "el-icon-s-home" },
  //   component: Layout,

  //   children: [
  //     {
  //       path: "",
  //       meta: { name: "合同列表", icon: "list" },
  //       component: () => import('../views/orderManagement/orderManagement.vue'),
  //     },

  //   ]
  // },
  //客服
  {
    path: "/phone",
    meta: { name: "客服管理", icon: "el-icon-s-home" },
    component: Layout,

    children: [
      {
        path: "",
        meta: { name: "客服电话", icon: "list" },
        component: () => import('../views/phone/index.vue'),
      },

    ]
  },

  
  {
    path: "/audit",
    meta: { name: "审核管理", icon: "el-icon-s-home" },
    component: Layout,

    children: [
      {
        path: "",
        meta: { name: "审核列表", icon: "list" },
        component: () => import('../views/orderManagement/audit.vue'),
      },

    ]
  },
  

];

export default new Router({
  mode: "hash",
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes,
});